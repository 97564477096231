/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import "./styles.scss";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import { MULTIPLE_TYPE_ENUM } from "@kargo/shared-components.krg-select/shared/enums";
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from "@kargo/shared-components.krg-button";
import { useState } from "react";
import { HelpOutline } from "@mui/icons-material";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { User } from "../../api/types";
import Select from "../Select";

type UsersTableProps = {
  user: User;
  domains?: string[];
  submit: (obj?: any) => any;
  close: () => any;
  style?: React.CSSProperties;
  cancelButtonEnabled?: boolean;
  submitButtonEnabled?: boolean;
  changeCancelButtonState?: (obj?: any) => any;
  changeSubmitButtonState?: (obj?: any) => any;
};
export default function UserForm({
  style,
  user,
  domains = [],
  submit,
  close,
  cancelButtonEnabled = true,
  submitButtonEnabled = true,
  changeCancelButtonState,
  changeSubmitButtonState,
}: UsersTableProps) {
  const [userObj, setUserObj] = useState({
    ...user,
    role: user.role?.toLowerCase(),
  });
  const [buttonEnabled, setButtonEnabled] = useState(
    submitButtonEnabled && userObj.username.length > 1,
  );
  const handleNameChange = (name) => {
    setUserObj({ ...userObj, name });
  };
  const handleEmailChange = (email) => {
    setUserObj({ ...userObj, email });
    if (email.length && email.indexOf("@") > -1 && userObj.role)
      setButtonEnabled(true);
  };
  const handlePropertiesChange = (newDomains) => {
    /* Faking the properties select label */
    if (newDomains.length > 1 && newDomains.includes("empty")) {
      newDomains.splice(newDomains.indexOf("empty"), 1);
    }
    if (newDomains.length === 0) {
      newDomains.push("empty");
    }
    setUserObj({ ...userObj, domains: newDomains });
  };
  const handleRoleChange = (role) => {
    setUserObj({ ...userObj, role });
    if (role && userObj.email.indexOf("@") > -1) setButtonEnabled(true);
  };
  const handleSubmit = () => {
    changeSubmitButtonState(false);
    if (userObj.domains?.length > 0 && userObj.domains?.includes("empty")) {
      userObj.domains.splice(userObj.domains.indexOf("empty"), 1);
    }
    submit(userObj);
  };

  const roleTooltip = (
    <div>
      Admin:
      <br />
      <li>
        User management controls over all properties. Controls player creation,
        player functionality, video content management, and playlist creation
        for each property. Can access reporting for all properties.
      </li>
      <br />
      Publisher:
      <br />
      <li>
        Video content management, playlist creation, and editing permissions on
        assigned properties.
      </li>
    </div>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexFlow: "column", width: "47%" }}>
          <KrgTextInput
            text={userObj.name}
            name="name"
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            label="Name"
            onTextChange={handleNameChange}
            debounceTime={0}
            onDebounceEnd={handleNameChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "47%",
            marginLeft: "50px",
          }}
        >
          <KrgTextInput
            text={userObj.email}
            name="email"
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            label="Email"
            onTextChange={handleEmailChange}
            debounceTime={0}
            onDebounceEnd={handleEmailChange}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", marginTop: "25px" }}>
        <Box sx={{ display: "flex", flexFlow: "column", width: "30%" }}>
          <Select
            name="domains"
            className="select-no-padding"
            value={userObj.domains || ["empty"]}
            items={[
              {
                text: "Properties",
                value: "empty",
                isHidden: userObj.domains && userObj.domains.length > 0,
              },
            ].concat(
              typeof domains?.length !== "undefined"
                ? domains.map((d) => ({
                    text: d,
                    value: d,
                    isHidden: false,
                  }))
                : [],
            )}
            isMultiple
            multipleType={MULTIPLE_TYPE_ENUM.checkmark}
            onChange={handlePropertiesChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "22%",
            marginLeft: "25px",
          }}
        >
          <Select
            name="role"
            value={userObj.role || "empty"}
            items={[
              {
                text: "User Role",
                value: "empty",
                isHidden: userObj.role && userObj.role.length > 0,
              },
              { text: "Admin", value: "admin", isHidden: false },
              { text: "Analyst", value: "analyst", isHidden: false },
              { text: "Editor", value: "editor", isHidden: false },
            ]}
            isFullWidth
            onChange={handleRoleChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "5%",
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <KrgTooltip tooltip={roleTooltip}>
            <HelpOutline sx={{ color: "#91a0ad", fontSize: "25px" }} />
          </KrgTooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "15%",
            marginLeft: "70px",
          }}
        >
          <KrgButton
            theme={BUTTON_THEME_ENUM.v2}
            variant={BUTTON_VARIANT_ENUM.outlined}
            className="button-height-35"
            sx={{ fontSize: "16px" }}
            text="Cancel"
            onClick={close}
            isEnabled={cancelButtonEnabled}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "15%",
            marginLeft: "20px",
          }}
        >
          <KrgButton
            theme={BUTTON_THEME_ENUM.v2}
            variant={BUTTON_VARIANT_ENUM.contained}
            className="button-height-35"
            sx={{ fontSize: "16px" }}
            text={`${user.username.length ? "Save" : "Invite"}`}
            onClick={handleSubmit}
            isEnabled={submitButtonEnabled && buttonEnabled}
          />
        </Box>
      </Box>
    </>
  );
}
