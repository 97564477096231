import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Box, Typography } from "@mui/material";
import KrgDataTable from "@kargo/shared-components.krg-data-table";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import KrgChip from "@kargo/shared-components.krg-chip";
import KrgModal from "@kargo/shared-components.krg-modal";
import { makeStyles } from "@mui/styles";
import { Cancel, AddCircleOutline } from "@mui/icons-material";
import { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import TagSelect from "../TagSelect/TagSelect";
import {
  AutocompleteProps,
  BulkEditType,
  SnackbarState,
} from "../../@types/media.types";
import { Video } from "../../api/types";
import CategoriesSelect from "../Select/CategoriesSelect";
import { capitalizeStrings } from "../../helpers/capitilizeStrings";
import { chipStyle } from "../VideosTable/VideosTable";
import { Query } from "../../hooks/useVideos";
import useAPI from "../../api/useAPI";
import ConfirmationSnackbar from "../Snackbar/ConfirmationSnackbar";
import { MAX_BULK_EDIT_LIMIT } from "../../constants/constants";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import useTags from "../../hooks/useTags";

const useStyles = makeStyles({
  dialogPaper: (props: { bulkEditType: BulkEditType }) => ({
    "& .MuiDialog-paper": {
      minWidth: props.bulkEditType === BulkEditType.TAGS ? "1152px" : "620px",
      height: "auto !important",
      padding: "20px",
      margin: 0,
    },
    "& .MuiDialog-paper > div:nth-of-type(3), & .MuiDialog-paper > div:nth-of-type(4)":
      {
        marginTop: "30px",
      },
  }),
  dataTableContainer: {
    border: "1px solid #91A0AD",
    marginTop: "30px",
  },
});

type Props = {
  asAdmin?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  videos: Video[];
  bulkEditType: BulkEditType;
  query?: Query;
};

const BulkEditMediaModal = ({
  asAdmin,
  isOpen,
  onClose,
  videos,
  bulkEditType,
  query,
}: Props) => {
  const classes = useStyles({ bulkEditType });
  const queryClient = useQueryClient();
  const { publisherSlug } = usePublisherContext(asAdmin);
  const { api } = useAPI();
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedTags, setSelectedTags] = useState<AutocompleteProps[]>([]);
  const [selectedCategories, setSelectedCategories] =
    useState<AutocompleteProps>(null);
  const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
  const [isSubmitEnabled, setISubmitEnabled] = useState(true);
  const [allVideos, setAllVideos] = useState<Video[]>([]);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: TYPE_ENUM.success,
    isOpen: false,
    message: "",
  });
  const tagsQuery = {
    ...(publisherSlug && { slug: publisherSlug }),
  };
  const { refetch: refetchTags } = useTags(tagsQuery);
  const handleToggleAll = (checked: boolean) => {
    setIsAllChecked(!isAllChecked);
    setSelectedVideos(checked ? allVideos : []);
  };

  const removeVideoTags = (
    existingVideos: Video[],
    token: string,
    removedTag: string,
  ): Video[] =>
    existingVideos.map((video) =>
      video.token === token
        ? { ...video, tags: video.tags.filter((tag) => tag !== removedTag) }
        : video,
    );

  const handleCloseSnackbar = () =>
    setSnackbarState({ ...snackbarState, isOpen: false });

  const handleRemoveTags = (row, removedTag: string) => {
    setAllVideos((prevAllVideos) =>
      removeVideoTags(prevAllVideos, row.token, removedTag),
    );
    setSelectedVideos((prevSelectedVideos) =>
      removeVideoTags(prevSelectedVideos, row.token, removedTag),
    );
  };

  const handleAddTags = () => {
    const tagsToAdd = selectedTags.map((tag) => tag.name);
    setAllVideos((prevAllVideos) =>
      prevAllVideos.map((video) =>
        selectedVideos.some(
          (selectedVideo) => selectedVideo.token === video.token,
        )
          ? {
              ...video,
              tags: Array.from(new Set([...video.tags, ...tagsToAdd])),
            }
          : video,
      ),
    );
    setSelectedVideos((prevSelectedVideos) =>
      prevSelectedVideos.map((video) => ({
        ...video,
        tags: Array.from(new Set([...video.tags, ...tagsToAdd])),
      })),
    );
    setSelectedTags([]);
  };
  const handleSubmit = async () => {
    try {
      if (selectedVideos.length > MAX_BULK_EDIT_LIMIT) {
        setSnackbarState({
          type: TYPE_ENUM.error,
          isOpen: true,
          message: `Maximum videos count for bulk edit is ${MAX_BULK_EDIT_LIMIT}`,
        });
        return;
      }
      setISubmitEnabled(false);
      if (bulkEditType === BulkEditType.CATEGORY) {
        await api.Media.bulkEditCategory({
          tokens: allVideos.map((video) => video.token),
          category: selectedCategories.name,
          group: publisherSlug,
        });
      } else if (bulkEditType === BulkEditType.TAGS) {
        await api.Media.bulkEditTags({
          medias: selectedVideos,
          group: publisherSlug,
        });
      } else {
        return;
      }
      queryClient.invalidateQueries(["videos", query]);
      setSnackbarState({
        type: TYPE_ENUM.success,
        isOpen: true,
        message: `Successfully updated ${bulkEditType}`,
      });
    } catch (error) {
      setSnackbarState({
        type: TYPE_ENUM.error,
        isOpen: true,
        message: `Failed to edit ${bulkEditType}`,
      });
    } finally {
      setISubmitEnabled(true);
    }
  };

  const handleRowToggle = (selectedVideo: Video, isChecked: boolean) => {
    if (isChecked) {
      setSelectedVideos([...selectedVideos, selectedVideo]);
    } else {
      setSelectedVideos(
        selectedVideos.filter((video) => video.token !== selectedVideo.token),
      );
    }
  };

  const videoTagChips = (row, tags: string[]) => (
    <>
      {tags.map((tag) => (
        <KrgChip
          key={tag}
          style={chipStyle}
          {...(row.isChecked && {
            startAdornment: (
              <Cancel
                sx={{ color: "#91A0AD !important" }}
                onClick={() => handleRemoveTags(row, tag)}
              />
            ),
          })}
          theme={KrgChip.THEME_ENUM.v2}
          type={KrgChip.TYPE_ENUM.transparent}
          data={{ text: tag, id: tag }}
        />
      ))}
    </>
  );

  const tagsBulkEdit = () => (
    <>
      <Box display="flex" alignItems="center">
        <TagSelect
          value={selectedTags}
          onChange={setSelectedTags}
          asAdmin={asAdmin}
        />
        {selectedTags.length > 0 && (
          <Typography
            variant="h6"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            style={{
              fontSize: "11px",
              marginLeft: "5px",
              padding: "8px 8px 8px 12px",
              cursor: "pointer",
            }}
            onClick={() => handleAddTags()}
          >
            <AddCircleOutline sx={{ marginRight: "4px" }} /> Add
          </Typography>
        )}
      </Box>
      <Box sx={{ border: "1px solid #91A0AD", marginTop: "30px" }}>
        <KrgDataTable
          theme={KrgDataTable.THEME_ENUM.v2}
          config={{
            pagination: {
              initialPageSize: 5,
            },
            data: {
              columns: [
                {
                  type: KrgDataTable.CELL_TYPE_ENUM.checkbox,
                  headerName: "",
                  field: "isChecked",
                  renderHeader: () => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={isAllChecked}
                      onToggle={handleToggleAll}
                    />
                  ),
                  renderCell: (row: Video & { isChecked: boolean }) => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={row.isChecked}
                      onToggle={(isChecked) => handleRowToggle(row, isChecked)}
                    />
                  ),
                },
                {
                  headerName: "Video Title",
                  field: "video_title",
                  width: "300px",
                },
                {
                  headerName: "Tags",
                  field: "tags",
                  renderCell: (row) => videoTagChips(row, row.tags || []),
                },
              ],
              rows: allVideos.map((video) => ({
                ...video,
                video_title: video.title,
                isChecked: selectedVideos.some(
                  (selectedVideo) => selectedVideo.token === video.token,
                ),
              })),
            },
          }}
        />
      </Box>
      <Box sx={{ fontSize: "12px", marginTop: "12px" }}>
        Selected Videos {selectedVideos.length}
      </Box>
    </>
  );

  useEffect(() => {
    if (videos.length > 0 && isOpen) {
      refetchTags();
      setAllVideos(videos);
      if (bulkEditType === BulkEditType.TAGS) {
        setIsAllChecked(true);
        setSelectedVideos(videos);
      }
    }
    if (!isOpen) {
      setIsAllChecked(false);
      setSelectedVideos([]);
    }
  }, [videos, isOpen]);

  return (
    <KrgModal
      className={`confirm-modal ${classes.dialogPaper}`}
      isOpen={isOpen}
      onClose={onClose}
      header={`Bulk Edit ${capitalizeStrings(bulkEditType)}`}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.confirmation}
      inputContainer={
        <>
          {bulkEditType === BulkEditType.TAGS ? (
            tagsBulkEdit()
          ) : (
            <>
              <CategoriesSelect
                value={selectedCategories}
                onChange={setSelectedCategories}
                isMultiple={false}
                hasCheckbox={false}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  fontSize: "12px",
                }}
              >
                Selected Videos {videos.length}
              </Box>
            </>
          )}
          <ConfirmationSnackbar
            type={snackbarState.type}
            isOpen={snackbarState.isOpen}
            onClose={handleCloseSnackbar}
            message={snackbarState.message}
          />
        </>
      }
      submitButtonText="Save"
      cancelButtonText="Cancel"
      isSubmitButtonEnabled={
        isSubmitEnabled &&
        (selectedCategories !== null || selectedVideos.length > 0)
      }
      onSubmitClick={() => handleSubmit()}
    />
  );
};

export default BulkEditMediaModal;
