import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import { UserInput } from "../api/types";

type UserQuery = {
  title?: string;
  username?: string;
  slug?: string;
  isPublisherAdmin?: boolean;
};

export default function useUsers(inputQuery: UserQuery = {}) {
  const queryClient = useQueryClient();
  const { api, didLoad, userProfile, setUserProfile } = useAPI();
  const fetchUsers = useCallback(
    () => api && api.getUsers(inputQuery),
    [api, inputQuery],
  );
  const query = useQuery(["users", inputQuery], () => fetchUsers(), {
    enabled: didLoad,
    staleTime: Infinity,
  });
  const createMutation = useMutation({
    mutationFn: (input: UserInput) => api.createUser(input),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: (username: string) => api.deleteUser(username),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
  const updateMutation = useMutation({
    mutationFn: ({ username, data }: { username: string; data: UserInput }) =>
      api.updateUser(username, data),
    onSuccess: (resp) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (userProfile.username === resp.username) {
        setUserProfile({
          username: resp.username,
          attributes: resp,
          groups: resp.groups,
        });
      }
    },
  });
  const inviteMutation = useMutation({
    mutationFn: (input: UserInput) => api.inviteUser(input),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
  return {
    query,
    users: query.data?.users || [],
    api: {
      create: createMutation.mutate,
      delete: deleteMutation.mutate,
      update: updateMutation.mutate,
      invite: inviteMutation.mutate,
    },
    mutations: {
      createMutation,
      deleteMutation,
      updateMutation,
    },
  };
}
