import React from "react";
import AutoComplete from "../AutoComplete/AutoComplete";
import { AutocompleteProps } from "../../@types/media.types";
import { tier1Categories } from "../../constants/iab-t1-categories-v3";

type Props = {
  isEnabled?: boolean;
  onChange?: (any) => void;
  value?: AutocompleteProps[] | AutocompleteProps;
  isMultiple?: boolean;
  hasCheckbox?: boolean;
};

export default function CategoriesSelect({
  isEnabled = true,
  value = [],
  isMultiple = true,
  onChange,
  hasCheckbox = true,
}: Props) {
  return (
    <AutoComplete
      id="categories-select"
      isEnabled={isEnabled}
      label="Categories"
      placeholder="Add"
      value={value}
      isMultiple={isMultiple}
      onChange={onChange}
      options={tier1Categories}
      hasCheckbox={hasCheckbox}
    />
  );
}
