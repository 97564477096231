import React, { FormEvent, useRef, useState } from "react";
import KrgTextInput, {
  THEME_ENUM,
  VARIANT_ENUM,
} from "@kargo/shared-components.krg-text-input";
import { Box, FormControl, FormGroup, Grid, InputLabel } from "@mui/material";
import KrgChip, { TYPE_ENUM } from "@kargo/shared-components.krg-chip";
import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";
import KrgSelect, {
  ItemI,
  MULTIPLE_TYPE_ENUM,
  SIZE_ENUM,
} from "@kargo/shared-components.krg-select";
import {
  GenerateFormType,
  PlaylistCategoryInput,
  PlaylistTagInput,
} from "../../../@types/playlist.types";
import { useFormContext } from "../../../context/form/form.provider";
import { FormActionsEnum } from "../../../@types/form.types";
import {
  handleAddTag,
  handleDeleteTag,
  handleOnChange,
  submitForm,
} from "../../../context/form/form.actions";
import { HinterText } from "../../../pages/media-gallery/single-media/SingleMediaEditor";
import LoadingCircle from "../../LoadingCircle";
import ContentModal from "../ContentModal";
import InfoPopover from "../../InfoPopover";
import usePlaylists from "../../../hooks/usePlaylists";
import useSnackbar from "../../../hooks/useSnackbar";
import { capitalizeStrings } from "../../../helpers/capitilizeStrings";
import "./styles.scss";
import { tier1Categories } from "../../../constants/iab-t1-categories-v3";

type GeneratePlaylistByTagProps = {
  type: GenerateFormType;
  isOpen?: boolean;
  onClose: () => void;
};
export const GeneratePlaylistByType = ({
  type,
  isOpen,
  onClose,
}: GeneratePlaylistByTagProps) => {
  const [{ formInputFields, errors }, dispatch] = useFormContext();
  const excludedTagInputRef = useRef<HTMLInputElement>();
  const tagInputRef = useRef<HTMLInputElement>();
  const { title, tags, categories, excludedTags, sort } = formInputFields;
  const { api } = usePlaylists();
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const parseFormToRequestData = () => {
    const data = {
      title: title.value,
      key: (title.value as string).trim().replace(/ /g, "-"),
      sort: sort.value,
      categories: categories.value,
      tags: (tags.value as ChipData[]).map((tag) => tag.text),
      excludedTags: (excludedTags.value as ChipData[]).map((tag) => tag.text),
    };
    if (type === "category") {
      delete data.excludedTags;
      delete data.tags;
    } else {
      delete data.categories;
    }
    return data;
  };

  const cleanAndCloseForm = () => {
    dispatch({
      type: FormActionsEnum.RESET_FORM,
      payload: {
        ...formInputFields,
        sort: { ...sort, value: "" },
        categories: { ...categories, value: [] },
        tags: { ...tags, value: [] },
        excludedTags: { ...excludedTags, value: [] },
        title: { ...title, value: "" },
      },
    });
    onClose();
  };

  const handleSelect = (value) => {
    setSelectedCategories(value);
    handleOnChange(dispatch, categories, value);
  };

  const handleRequestByType = (
    data: PlaylistCategoryInput | PlaylistTagInput,
  ) => {
    setIsLoading(true);
    const onSuccess = () => {
      snackbarSuccess(`Playlist ${data.title} created`);
      cleanAndCloseForm();
    };
    if (type === "category") {
      api.createByCategory(data, {
        onSuccess,
        onError: () => snackbarError("Something went wrong, try again later"),
        onSettled: () => setIsLoading(false),
      });
    } else {
      api.createByTag(data, {
        onSuccess,
        onError: () => snackbarError("Something went wrong, try again later"),
        onSettled: () => setIsLoading(false),
      });
    }
  };

  const submitHandler = async (ev: FormEvent | MouseEvent) => {
    ev.preventDefault();
    const data = parseFormToRequestData();
    submitForm(dispatch, errors, data, handleRequestByType);
  };

  const allRequeiredIsFilled = () =>
    !Object.keys(formInputFields).some(
      (k) => formInputFields[k].required && !formInputFields[k].value,
    );

  return (
    <ContentModal
      title={`Generate Playlist by ${capitalizeStrings(type)}`}
      content={
        isLoading ? (
          <Grid container justifyContent="center">
            <LoadingCircle style={{ height: 50, width: 50, margin: 50 }} />
          </Grid>
        ) : (
          <div className="content-box">
            <Box id="media-editor-form-container">
              <Box display="flex" width="100%">
                {/* title */}
                {title && (
                  <FormGroup
                    className={`form-group-container ${errors?.title && "error-field-group"}`}
                  >
                    <InputLabel>{title.label}</InputLabel>
                    <FormControl error={!!errors?.title}>
                      <KrgTextInput
                        id="media-title"
                        name={title.name || "title"}
                        variant={VARIANT_ENUM.outlined}
                        text={title.value?.toString() || ""}
                        style={{ height: "auto" }}
                        onTextChange={(text) =>
                          handleOnChange(dispatch, title, text)
                        }
                      />
                      <HinterText
                        text={title.hinterText}
                        errors={errors?.title}
                      />
                    </FormControl>
                  </FormGroup>
                )}
                {/* sort */}
                {sort && sort.options && (
                  <FormGroup
                    className={`form-group-container ${errors?.sort && "error-field-group"}`}
                  >
                    <InputLabel style={{ marginLeft: 16 }}>
                      {sort.label}
                      {sort.popoverText && (
                        <InfoPopover text={sort.popoverText} />
                      )}
                    </InputLabel>
                    <FormControl error={!!errors?.sort}>
                      <KrgSelect
                        style={{ marginLeft: 16 }}
                        value={sort.value as string}
                        items={sort.options as ItemI[]}
                        onChange={(value: string) =>
                          handleOnChange(dispatch, sort, value)
                        }
                      />
                      <HinterText
                        text={sort.hinterText}
                        errors={errors?.sort}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Box>
              {type === "category" ? (
                <>
                  {/* category */}
                  {categories && (
                    <FormGroup
                      className={`form-group-container ${errors?.categories && "error-field-group"}`}
                    >
                      <InputLabel>
                        {categories.label}
                        {categories.popoverText && (
                          <InfoPopover text={categories.popoverText} />
                        )}
                      </InputLabel>
                      <FormControl error={!!errors?.categories}>
                        <KrgSelect
                          value={selectedCategories}
                          items={
                            tier1Categories.map((cat) => ({
                              text: cat.name,
                              value: cat.name,
                            })) as ItemI[]
                          }
                          multipleType={MULTIPLE_TYPE_ENUM.checkmark}
                          size={SIZE_ENUM.small}
                          onChange={(value: string) => handleSelect(value)}
                          isMultiple
                        />
                      </FormControl>
                    </FormGroup>
                  )}
                </>
              ) : (
                <>
                  {/* tags */}
                  {tags && (
                    <FormGroup
                      className={`form-group-container ${errors?.tags && "error-field-group"}`}
                    >
                      <InputLabel>
                        {tags.label}
                        {tags.popoverText && (
                          <InfoPopover text={tags.popoverText} />
                        )}
                      </InputLabel>
                      <FormControl error={!!errors?.tags}>
                        <Box
                          className="chip-container-tag"
                          onClick={() => {
                            tagInputRef.current?.focus();
                          }}
                        >
                          {tags.value &&
                            (tags.value as ChipData[]).map((t: ChipData) => (
                              <KrgChip
                                key={`chip-${t.id}`}
                                theme={THEME_ENUM.v2}
                                type={TYPE_ENUM.gray}
                                data={{ text: t.text, id: t.id }}
                                onDelete={() =>
                                  handleDeleteTag(dispatch, t, tags)
                                }
                              />
                            ))}
                          <input
                            id="tag"
                            type="text"
                            onKeyDown={(ev) =>
                              handleAddTag(dispatch, ev, tags, tagInputRef)
                            }
                            placeholder="Add a tag"
                            ref={tagInputRef}
                          />
                        </Box>
                      </FormControl>
                    </FormGroup>
                  )}
                  {/* tags */}
                  {excludedTags && (
                    <FormGroup
                      className={`form-group-container ${errors?.excludedTags && "error-field-group"}`}
                    >
                      <InputLabel>
                        {excludedTags.label}
                        {excludedTags.popoverText && (
                          <InfoPopover text={excludedTags.popoverText} />
                        )}
                      </InputLabel>
                      <FormControl error={!!errors?.excludedTags}>
                        <Box
                          className="chip-container-tag"
                          onClick={() => {
                            excludedTagInputRef.current?.focus();
                          }}
                        >
                          {excludedTags.value &&
                            (excludedTags.value as ChipData[]).map(
                              (t: ChipData) => (
                                <KrgChip
                                  key={`chip-${t.id}`}
                                  className="excluded-chip"
                                  theme={THEME_ENUM.v2}
                                  type={TYPE_ENUM.gray}
                                  data={{ text: t.text, id: t.id }}
                                  onDelete={() =>
                                    handleDeleteTag(dispatch, t, excludedTags)
                                  }
                                />
                              ),
                            )}
                          <input
                            id="exclued-tag"
                            type="text"
                            onKeyDown={(ev) =>
                              handleAddTag(
                                dispatch,
                                ev,
                                excludedTags,
                                excludedTagInputRef,
                              )
                            }
                            placeholder="Add a tag"
                            ref={excludedTagInputRef}
                          />
                        </Box>
                      </FormControl>
                    </FormGroup>
                  )}
                </>
              )}
            </Box>
          </div>
        )
      }
      isOpen={isOpen}
      onClose={cleanAndCloseForm}
      submitButtonText="Generate"
      cancelButtonText="cancel"
      onSubmit={submitHandler}
      isSubmitButtonEnabled={allRequeiredIsFilled()}
    />
  );
};
