import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { PATHS } from "../../../constants/paths";
import LoadingCircle from "../../../components/LoadingCircle";
import { SinglePlaylistURLParams } from "../../../@types/url.types";
import { FormProvider } from "../../../context/form/form.provider";
import useSinglePageSidebar from "../../../hooks/useSinglePageSidebar";
import { SidebarListItemButtonProps } from "../../../@types/sidebar.types";
import SidebarBackIconItem from "../../../components/Sidebar/SidebarBackIconItem";
import { FormInitInputs } from "../../../@types/form.types";
import SinglePlaylistPageContainer from "./SinglePlaylistPageContainer";
import {
  PLAYLIST_MIN_TITLE_LENGTH,
  PLAYLIST_MAX_TITLE_LENGTH,
  PLAYLIST_SORT_TYPES,
} from "../../../constants/constants";
import {
  PlaylistCreationEnum,
  PlaylistResponse,
} from "../../../@types/playlist.types";
import usePlaylist from "../../../hooks/usePlaylist";
import { tier1Categories } from "../../../constants/iab-t1-categories-v3";

const sidebarItem: SidebarListItemButtonProps = {
  selected: false,
  text: "Media Gallery",
  path: PATHS.MEDIA_GALLERY_PLAYLISTS,
  iconElement: <SidebarBackIconItem />,
};

const SinglePlaylistPage = () => {
  // Show single page sidebar
  useSinglePageSidebar([sidebarItem]);

  const { token } = useParams<SinglePlaylistURLParams>();
  if (!token) throw new Error("No ID found in media data");

  const { playlist } = usePlaylist(token);

  if (!playlist)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <LoadingCircle />
      </Box>
    );

  const {
    title,
    tags,
    sortType,
    categories, // ["attractions"]
    excludedTags,
    type = PlaylistCreationEnum.NONE, // TODO: remove after API was modified
  }: PlaylistResponse = playlist;

  const categoryOptions = tier1Categories.map(({ name }) => ({
    value: name.toLowerCase(),
    text: name,
  }));
  const categoryDefaultValue: string[] = categories
    ? categories.map((val: string) => {
        const value = val.trim().toLowerCase();
        return value;
      })
    : [];

  const formInitialState: FormInitInputs = {
    title: {
      name: "title",
      label: "Playlist Title",
      type: "text",
      required: true,
      rules: {
        length: {
          min: PLAYLIST_MIN_TITLE_LENGTH,
          ...(PLAYLIST_MAX_TITLE_LENGTH && { max: PLAYLIST_MAX_TITLE_LENGTH }),
        },
      },
      errorMessage: `Video Title is required and should be min ${PLAYLIST_MAX_TITLE_LENGTH} characters length`,
      value: title || "",
      hinterText: "*required",
    },
    sortType: {
      name: "sortType",
      label: "Video Sort",
      type: "select",
      required: true,
      value:
        PLAYLIST_SORT_TYPES.find(({ value }) => value === sortType)?.value ||
        PLAYLIST_SORT_TYPES[0].value,
      errorMessage: "At least one sort type is required",
      options: PLAYLIST_SORT_TYPES,
      popoverText: "Please choose videos sort",
    },
    ...(type === PlaylistCreationEnum.CATEGORY && {
      categories: {
        name: "categories",
        label: "Categories",
        type: "select",
        required: true,
        value: categoryDefaultValue,
        errorMessage: "Only one category selection allowed",
        options: categoryOptions,
        popoverText: "Please select a category for your video",
        isMultiple: true,
      },
    }),
    ...(type === PlaylistCreationEnum.TAG && {
      tags: {
        name: "tags",
        label: "Tags",
        type: "chip",
        required: true,
        value: tags?.map((tag: string) => ({ id: tag, text: tag })) || [],
        popoverText: "Commas or semicolon will separate the tags",
      },
      excludedTags: {
        name: "excludedTags",
        label: "Exclude Tags",
        type: "chip",
        value:
          excludedTags?.map((tag: string) => ({ id: tag, text: tag })) || [],
        popoverText: "Commas or semicolon will separate the excluded tags",
      },
    }),
  };

  return (
    <FormProvider formInputFields={formInitialState}>
      <SinglePlaylistPageContainer
        token={token}
        playlist={playlist}
        initialFormState={formInitialState}
        creationType={type}
      />
    </FormProvider>
  );
};

export default SinglePlaylistPage;
