import { HTMLInputTypeAttribute, JSX } from "react";
import { ItemI } from "@kargo/shared-components.krg-select";
import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";
import { AtLeastOneLength } from "./shared.types";

// NOTE: some actions are doing very similar things, for used context they are separated with name
export enum FormActionsEnum {
  ADD_TAG = "ADD_TAG",
  REMOVE_TAG = "REMOVE_TAG",
  CHANGE_FORM_FIELD = "CHANGE_FORM_FIELD",
  VALIDATE_FORM_FIELD = "VALIDATE_FORM_FIELD",

  RESET_FORM = "RESET_FORM",
  SET_FORM_TOUCHED = "SET_FORM_TOUCHED",

  SET_FORM_ERROR = "SET_FORM_ERROR",
  CLOSE_FORM_ERROR = "CLOSE_FORM_ERROR",

  SET_FORM_SUBMITTING = "SET_FORM_SUBMITTING",
  SET_FORM_SUBMITTED = "SET_FORM_SUBMITTED",
  SET_FORM_SUBMIT_ERROR = "SET_FORM_SUBMIT_ERROR",
  SET_FORM_SUBMIT_SUCCESS = "SET_FORM_SUBMIT_SUCCESS",
}
export type FormActionTypeValue = FormActionsEnum[keyof FormActionsEnum];

export enum ActionOnFieldEnum {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

type FormRules = {
  isURL?: boolean;
  pattern?: RegExp;
  length?: AtLeastOneLength;
  selectionLength?: AtLeastOneLength;
};

export type FormRuleKey = keyof FormRules;
export type FormRuleValue = FormRules[keyof FormRules];
export type FormInitInputs = { [key: string]: FormInputFields };
export type FormOptionField = {
  id: string | number;
  text: string;
};

export type FormValue =
  | string
  | number
  | boolean
  | ItemI
  | ChipData
  | string[]
  | number[]
  | ItemI[]
  | ChipData[];

export type FormInputTypeAttribute = HTMLInputTypeAttribute | "select" | "chip";

export type FormInputFields = {
  name: string;
  type: FormInputTypeAttribute;
  label?: string;
  required?: boolean;
  rules?: FormRules;
  touched?: boolean; // Has to be false by default, and should be set
  value?: FormValue;
  error?: string[];
  errorMessage?: string;
  dependentOn?: keyof FormInitInputs;
  popoverText?: string;
  hinterText?: string;
  options?: FormOptionField[] | ChipData[] | ItemI[];
  isMultiple?: boolean;
  defaultValue?: FormValue;
};

export type FormError = { [x: string]: string[] };

export type FormContextState = {
  formInputFields: FormInitInputs;
  touched: boolean;
  errors: FormError;
  data: { [key: string]: FormValue };
  showAllErrors: boolean;
  submitting: boolean;
  // TODO: could be improved to monitor if any of the fields have changed, and set touched
  initialInputs?: FormInitInputs;
};

export type FormActions =
  | {
      type: FormActionsEnum.RESET_FORM;
      payload: FormInitInputs;
    }
  | {
      // Actions without payload
      type:
        | FormActionsEnum.SET_FORM_TOUCHED
        | FormActionsEnum.SET_FORM_SUBMITTING
        | FormActionsEnum.SET_FORM_SUBMIT_SUCCESS
        | FormActionsEnum.CLOSE_FORM_ERROR
        | FormActionsEnum.SET_FORM_SUBMITTED;
      payload?: undefined;
    }
  | {
      type:
        | FormActionsEnum.CHANGE_FORM_FIELD
        | FormActionsEnum.VALIDATE_FORM_FIELD;
      payload: {
        field: FormInputFields;
        value: FormValue;
      };
    }
  | {
      type: FormActionsEnum.ADD_TAG | FormActionsEnum.REMOVE_TAG;
      payload: {
        value: ChipData | string[];
        field: FormInputFields;
      };
    }
  | {
      type:
        | FormActionsEnum.SET_FORM_ERROR
        | FormActionsEnum.SET_FORM_SUBMIT_ERROR;
      payload?: string;
    }
  | {
      type: FormActionsEnum.SET_FORM_SUBMIT_SUCCESS;
      payload: { [key: string]: FormValue };
    };

export type FormDispatch = ({ type, payload }: FormActions) => void;

export type FormContext = [state: FormContextState, dispatch: FormDispatch];

export type FormProvideProps = {
  children: JSX.Element;
  formInputFields: FormInitInputs;
};
